import type { ComponentType } from "react"
import { useRef, useEffect } from "react"

import type { MotionValue, Transition } from "framer-motion"
import {
    useScroll, // deprecated in Motion v6.5.1
    useVelocity,
    useTransform,
    useAnimation,
    useMotionValue,
    animate,
    useInView,
} from "framer-motion"

// Scrub through a video or drive a Lottie animation by scrolling
export function withScrolledProgress(Component): ComponentType {
    const startY = 0 // scroll position when animation starts
    const distance = 1000 // scroll distance after which animation ends
    const endY = startY + distance

    return (props) => {
        const ref = useRef(null)
        const isInView = useInView(ref)

        // const { scrollY } = useScroll()
        // const progress = useTransform(scrollY, [startY, endY], [0, 1])
        console.log("in component")

        useEffect(() => {
            if (isInView) {
                console.log(" in view")
            } else {
                console.log("not in view")
            }
        }, [isInView])

        return <Component {...props} ref={ref} />
    }
}
